import React from 'react';
import appstore from '../assets/images/app-store.png';
import playstore from '../assets/images/play-store.png';
import firstImage from '../assets/images/main-one.png';
import secondImage from '../assets/images/main-two.png';

import { ReactComponent as Learnmore } from '../assets/icons/learn-more.svg';
import { ReactComponent as WatchDemo } from '../assets/icons/watch-demo.svg';
import { ReactComponent as Youtube } from '../assets/icons/youtube-sm.svg';
import { ReactComponent as Instagram } from '../assets/icons/insta-sm.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter-sm.svg';
import { ReactComponent as Facebook } from '../assets/icons/fb-sm.svg';
import { ReactComponent as Tiktok } from '../assets/icons/tiktok-sm.svg';
import winner from '../assets/images/winner-person.png';
import second from '../assets/images/second-person.png';
import third from '../assets/images/third-person.png';

import '../styles/Hero.css';

function Hero() {
  return (
    <main className="hero-container">
      <div className="main-content">
        <div className="hero-content">
          <h1 className="hero-title">
            <span className="large-text">Play Games </span> <br />
            from your favorite Influencers, win Different Prizes.
          </h1>
          <p className="hero-description">
            Fun Comp is a social media accessory that empowers influencers,
            artists, content creators, businesses, organizations and ordinary
            people with a new way to engage, entertain and expand their
            followers with personalized games that allows the host and players
            an opportunity to earn thousands of dollars.{' '}
          </p>
          <div className="content-buttons">
            <div className="store-buttons">
              <img src={appstore} alt="appstorelink" />
              <img src={playstore} alt="playstorelink" />
            </div>
            <div className="other-buttons">
              <button className="learn-btn">
                <span>Learn More </span> <Learnmore />
              </button>
              <button className="demo-btn">
                Watch Demo <WatchDemo />
              </button>
            </div>
          </div>
        </div>

        <div className="hero-images">
          <div className="hero-image-container">
            <img className="hero-image" src={firstImage} alt="firstPerson" />
            <img className="hero-image" src={secondImage} alt="secondPerson" />
          </div>
          <div className="persons">
            <div className="hero-winner-box">
              <img src={winner} alt="winner" />
              <div className="winner-info">
                <p className="person-name">Raheel ab </p>
                <p className="person-standing">
                  <span className="first">1st</span> Place
                </p>
              </div>
              <p className="winner-prize">$125</p>
            </div>

            <div className="hero-second-box">
              <img src={third} alt="second" />
              <div className="second-info">
                <p className="person-name">J. Bristow </p>
                <p className="person-standing">
                  <span className="second">2nd</span> Place
                </p>
              </div>
              <p className="second-prize">$75</p>
            </div>

            <div className="hero-third-box">
              <img src={second} alt="third" />
              <div className="third-info">
                <p className="person-name">J. Buttler </p>
                <p className="person-standing">
                  <span className="third">3rd</span> Place
                </p>
              </div>
              <p className="third-prize">$25</p>
            </div>
          </div>
        </div>
      </div>
      <div className="social-bar">
        <Facebook className="icon-sm" />
        <Twitter className="icon-sm" />
        <Instagram className="icon-sm" />
        <Tiktok className="icon-sm" />
        <Youtube className="icon-sm" />
      </div>
    </main>
  );
}

export default Hero;
