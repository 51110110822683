import React, { useEffect, useState } from 'react';
import '../../styles/Modal.css';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross-mark.svg';
import Login from './Login';
import Signup from './SignUp';
import ForgotPass from './ForgotPass';
import CreateUserName from './CreateUserName';

const Modal = ({
  onClickOutside,
  setShowModal,
  user,
  setUser,
  userMail,
  setUserMail,
}) => {
  const modalRef = React.useRef(null);
  const [loginActive, setLoginActive] = useState(true);
  const [signUpActive, setSignUpActive] = useState(false);
  const [forgetActive, setForgetActive] = useState(false);
  const [createUserNameActive, setCreateUserNameActive] = useState(false);

  const handleSignUpClick = () => {
    setLoginActive(false);
    setSignUpActive(true);
  };

  const handleLoginClick = () => {
    setLoginActive(true);
    if (signUpActive) {
      setSignUpActive(false);
    }
    if (forgetActive) {
      setForgetActive(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
  return (
    <section ref={modalRef} className="modal-container">
      <div
        onClick={(showModal) => setShowModal(!showModal)}
        className="icon-wrapper"
      >
        <CrossIcon className="cross-icon" />
      </div>
      {forgetActive ? (
        <ForgotPass handleLoginClick={handleLoginClick} />
      ) : (
        <>
          <div className="modal-header">
            <p
              onClick={handleLoginClick}
              className={loginActive ? 'active' : ''}
            >
              Sign In
            </p>
            <p
              onClick={handleSignUpClick}
              className={signUpActive ? 'active' : ''}
            >
              Register
            </p>
          </div>
          {loginActive && (
            <Login
              user={user}
              setUser={setUser}
              userMail={userMail}
              setUserMail={setUserMail}
              setSignUpActive={handleSignUpClick}
              setForgetActive={setForgetActive}
            />
          )}
          {signUpActive && (
            <Signup
              user={user}
              setUser={setUser}
              userMail={userMail}
              setUserMail={setUserMail}
              createUserNameActive={createUserNameActive}
              setCreateUserNameActive={setCreateUserNameActive}
            />
          )}

          {createUserNameActive && (
            <CreateUserName userMail={userMail} setUserMail={setUserMail} />
          )}
        </>
      )}
    </section>
  );
};

export default Modal;
