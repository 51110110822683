import { useState } from 'react';
import '../../styles/Modal.css';
import ErrorHandler from '../ErrorHandler';
import { URLS } from '../../constants/urls';

const ForgotPass = ({ handleLoginClick }) => {
  const forgotURL = URLS.API_URL+'/auth/forgot-password';
  const [email, setEmail] = useState('');
  const [verifyStatus, setVerifyStatus] = useState({
    state: '',
    success: false,
    message: '',
  });

  const verifyOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
    }),
  };

  const getVerifyCode = async (e) => {
    e.preventDefault();
    try {
      setVerifyStatus({ ...verifyStatus, state: 'pending' });
      let res = await fetch(forgotURL, verifyOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        setVerifyStatus({
          ...verifyStatus,
          state: 'success',
          success: true,
          message: resJson.message,
        });
      } else {
        setVerifyStatus({
          ...verifyStatus,
          state: 'failed',
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };
  return (
    <div className="forgot-container">
      <h4 className="forgot-header">Forgot Password</h4>
      <div className="modal-body">
        <form className="modal-form">
          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setEmail(e.target.value)}
              required
              id="email"
              type="email"
              placeholder="Email"
            />
            <label htmlFor="email">Email</label>
          </div>
          <input
            onClick={getVerifyCode}
            type="submit"
            className="logIn-btn"
            value={
              verifyStatus.state === 'pending' ? 'Sending...' : 'Send Email'
            }
          />
        </form>
        {verifyStatus.success && (
          <p className="email-success-txt mb-20">
            An email has been sent to your account
          </p>
        )}

        {!verifyStatus.success && (
          <p className="error-message">{verifyStatus.message} </p>
        )}

        <p className="login-txt" onClick={handleLoginClick}>
          Back To Log In
        </p>
      </div>
    </div>
  );
};

export default ForgotPass;
