import React from 'react';
import '../styles/Hosts.css';
import blind1 from '../assets/images/blind-1.png';
import blind2 from '../assets/images/blind-2.png';
import blind3 from '../assets/images/blind-3.png';
import blind4 from '../assets/images/blind-4.png';
import blind5 from '../assets/images/blind-5.png';
import blind6 from '../assets/images/blind-6.png';
import blind7 from '../assets/images/blind-7.png';
import blind8 from '../assets/images/blind-8.png';

const images = [
  {
    src: blind1,
  },
  {
    src: blind2,
  },
  {
    src: blind3,
  },
  {
    src: blind4,
  },
  {
    src: blind5,
  },
  {
    src: blind6,
  },
  {
    src: blind7,
  },
  {
    src: blind8,
  },
];

function Hosts() {
  return (
    <div className="hosts-container">
      {images.map((image, index) => (
        <div key={index} className="image-wrapper">
          <img src={image.src} alt="hosts" />
          <div className="host-content">
            <h4>Blind Guesses</h4>
            <p>
              HOST: <span>Arifa12</span>
            </p>
            <p>
              PLAYS: <span>3,456</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Hosts;
