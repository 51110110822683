import { useEffect } from 'react';
import { useState } from 'react';
import '../../styles/Modal.css';
import ErrorHandler from '../ErrorHandler';

const CreateUserName = ({ userMail, setUserMail }) => {
  const [message, setMessage] = useState('');
  const userNameURL = 'https://api.funcomp.app/api/auth/username';
  const [name, setName] = useState('');

  const [token, setToken] = useState('');

  const [verifyStatus, setVerifyStatus] = useState({
    state: '',
    success: false,
    message: '',
  });

  const verifyOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: name,
      email: userMail,
    }),
  };

  const changeName = async (e) => {
    e.preventDefault();
    try {
      setVerifyStatus({ ...verifyStatus, state: 'pending' });
      let res = await fetch(userNameURL, verifyOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        const { data } = resJson;
        setToken(data.token);
        setVerifyStatus({
          ...verifyStatus,
          state: 'success',
          success: true,
          message: resJson.message,
        });
      } else {
        setVerifyStatus({
          ...verifyStatus,
          state: 'failed',
          success: false,
          message: resJson.message,
        });
      }
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  useEffect(() => {
    if (verifyStatus.success && token) {
      window.location.replace(
        `https://dashboard.funcomp.com/web-login?token=${token}`
      );
    }
  }, [verifyStatus.success, token]);

  return (
    <div className="username-container">
      <h4 className="username-header">Create UserName</h4>
      <div className="modal-body">
        <form onSubmit={changeName} className="modal-form">
          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setUserMail(e.target.value)}
              required
              id="email"
              type="email"
              placeholder="Email"
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="email-wrapper mb-20">
            <input
              onChange={(e) => setName(e.target.value)}
              required
              id="name"
              type="text"
              pattern="[A-Za-z0-9]+"
              onInvalid={() =>
                setMessage("UserName Can't contain any special characters")
              }
              placeholder="Name"
            />
            <label htmlFor="name">Name</label>
          </div>
          <input
            type="submit"
            className="logIn-btn"
            value={verifyStatus.state === 'pending' ? 'Changing...' : 'Change'}
          />
        </form>
        {message ? <p className="error-message">{message}</p> : null}
        {verifyStatus.success ? (
          <p className="success-text">{verifyStatus.message} </p>
        ) : (
          <p className="error-message">{verifyStatus.message}</p>
        )}

        {}
      </div>
    </div>
  );
};

export default CreateUserName;
