import { useEffect, useState } from 'react';

import { ReactComponent as PasswordHide } from '../../assets/icons/password-hide.svg';
import { ReactComponent as PasswordShow } from '../../assets/icons/password-show.svg';

import '../../styles/Modal.css';
import ErrorHandler from '../ErrorHandler';
import { URLS } from '../../constants/urls';

const Login = ({
  setSignUpActive,
  setForgetActive,
  setUser,
  userMail,
  setUserMail,
}) => {
  const [password, setPassword] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState({
    state: '',
    success: '',
    message: '',
  });
 

  const validateForm = () => {
    if (userMail.length === 0 || password.length === 0) {
      return true;
    } else return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setClicked(true);
    try {
      setStatus({ ...status, state: 'pending' });
      let res = await fetch(url, requestOptions);
      let resJson = await res.json();
      if (res.status === 200) {
        const { data } = resJson;
        setUser(data.user.firstName + ' ' + data.user.lastName);
        setStatus({ ...status, state: 'success' });
        setPassword('');
        setClicked(false);
        setStatus({
          ...status,
          success: resJson.success,
          message: resJson.message,
        });
        setToken(data.token);
      } else {
        setStatus({
          ...status,
          state: 'failed',
          success: resJson.success,
          message: resJson.message,
        });
      }
    } catch (error) {
      <ErrorHandler error={error} />;
    }
  };

  useEffect(() => {
    if (status.success && token) {
      window.location.replace(
        URLS.APP_URL+`web-login?token=${token}`
      );
    }
  }, [status.success, token]);

  const url = URLS.API_URL+'/auth/signin';
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: userMail,
      password: password,
    }),
  };

  return (
    <div className="modal-body fade-in-animation">
      <form onSubmit={handleSubmit} className="modal-form">
        <div className="email-wrapper mb-20">
          <input
            required
            id="email"
            type="email"
            placeholder="Email"
            onChange={(e) => setUserMail(e.target.value)}
            value={userMail}
          />
          <label htmlFor="email">Email</label>
        </div>
        <div className="password-wrapper mb-10">
          <div className="password-input-wrapper">
            <input
              required
              className="password-input"
              id="password"
              type={passwordShow ? 'text' : 'password'}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {passwordShow ? (
              <PasswordHide
                onClick={() => setPasswordShow(false)}
                className="password-icon"
              />
            ) : (
              <PasswordShow
                onClick={() => setPasswordShow(true)}
                className="password-icon"
              />
            )}
          </div>
          <label htmlFor="password">Password</label>
        </div>

        <div className="form-info mb-20">
          <p onClick={() => setForgetActive(true)} className="forgot-txt">
            Forgot Password
          </p>
        </div>
        {validateForm() && clicked && (
          <p className="error-message">
            Please fill in Email and Password fields
          </p>
        )}
        {status.success ? (
          <p className="success-text">Logged in Successfully</p>
        ) : (
          <input
            type="submit"
            className="logIn-btn"
            value={status.state === 'pending' ? 'Logging In' : 'Log In'}
          />
        )}
        {!status.success ? (
          <p className="error-message">{status.message}</p>
        ) : null}
        {/* <div className="modal-footer">
          <div className="divider">Or</div>
          <div className="login-icons">
            <a href="/auth-apple">
              <Apple className="login-icon" />
            </a>
            <GoogleLogin
              clientId={REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign In With Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              style={{ color: 'black' }}
              cookiePolicy={'signle-host-origin'}
              isSignedIn={true}
            />
          </div>
        </div> */}
        <p onClick={setSignUpActive} className="account-txt">
          Don't have an account?
          <span>
            <strong> Sign up</strong>
          </span>
        </p>
      </form>
    </div>
  );
};

export default Login;
