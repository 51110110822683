import hostIllustration from "../assets/images/host-illustration.png";

export default function Host() {
  return (
    <article className="influencer-container">
      <section className="games-container">
        <div className="host-text-wrapper">
          <p className="slant-host-text">HOST</p>
          <h4 className="influencer-content-title">
            Create <br /> <span>Personalized</span> <br /> Games
          </h4>
          <p className="influencer-content-dcrptn">
            Creating personalized games that cater to your audience is easy with
            Fun Comp. Simply apply to become a host. Once approved you can
            immediately begin offering yourself and fans a new way to engage and
            earn money together. That’s right! You make money to successfully
            host <span className="highlighted-text">Pay To Play</span> Games and
            your’ winning participants for your{" "}
            <span className="highlighted-text">Pay To Play</span> Games make
            money as well. Fun Comp is a win for yourself and your loyal
            followers.
          </p>

          <div class="host-content-btns">
            <button class="host-apply-btn">Apply here</button>
            <p>Or</p>
            <a className="host-download-button">Download the App</a>
          </div>
        </div>

        <div>
          <img
            src={hostIllustration}
            alt="Create personalised games on funcomp"
          />
        </div>
      </section>
    </article>
  );
}
