import React from 'react';
import '../styles/Games.css';

import cardOne from '../assets/images/card1.png';
import cardTwo from '../assets/images/card2.png';
import cardThree from '../assets/images/card3.png';
import { ReactComponent as Circle } from '../assets/icons/circle.svg';

function GameCard({ image, progress, prizes }) {
  return (
    <div className="game-card">
      <img src={image} alt="" />
      <div className="card-contents">
        <p className="gm-qstn">Which is good charcter in this game?</p>
        <div className="progress-bar">
          <div style={{ width: `${progress}` }} className="progress"></div>
        </div>
        <div className="seat-info">
          <Circle />
          <p>150 Seats</p>
        </div>
        <div className="prize-info">
          <Circle />
          <p>
            Prizes : <span>${prizes[0]}</span> <span className="hl">|</span>{' '}
            <span>${prizes[1]}</span> <span className="hl">|</span>{' '}
            <span>${prizes[2]}</span>
          </p>
        </div>
        <div className="join-btn">Join Now</div>
      </div>
    </div>
  );
}

function Games() {
  return (
    <div className="games-container">
      <div className="games-container-content">
        <h2>
          <span>Live</span> Games Competition.
        </h2>
        <p>
          Play live with thousands of other fans around, enjoy the fun with your
          favorite influencer, and earn $$$ in the process!
        </p>
      </div>
      <div className="games-container-cards">
        <GameCard image={cardOne} progress="70%" prizes={[100, 90, 70]} />
        <GameCard image={cardTwo} progress="10%" prizes={[50, 20, 10]} />
        <GameCard image={cardThree} progress="25%" prizes={[125, 75, 45]} />
      </div>
    </div>
  );
}

export default Games;
