import React from 'react';
import appStore from '../assets/images/app-store.png';
import googlePlay from '../assets/images/play-store.png';

import '../styles/Download.css';

function Download() {
  return (
    <div className="download-container">
      <h3>
        Download <strong>Funcomp</strong> today!
      </h3>
      <div className="download-store-buttons">
        <img className="grow" src={googlePlay} alt="google" />
        <img className="grow" src={appStore} alt="apple" />
      </div>
    </div>
  );
}

export default Download;
